/* eslint-disable no-empty */
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';

import { authProtectedRoutes as routes } from '../../routes';
import { initMenuSuccess, changeActiveMenuFromLocationSuccess } from './actions';
import { INIT_MENU, CHANGE_ACTIVE_MENU_FROM_LOCATION } from './constants';
import assignIds from './utils';

import { getLoggedInUser } from '@helpers/authUtils';

/**
 * Activate menu items from location
 * @param {*} menuItems
 */
const getActivatedMenuItemIds = (menuItems) => {
  var matchingItems = [];
  for (var menuItem of menuItems) {
    if (window.location.pathname.indexOf(menuItem.path) === 0) {
      matchingItems.push(menuItem.id);
    }

    if (typeof menuItem.children !== 'undefined') {
      matchingItems = [...matchingItems, ...getActivatedMenuItemIds(menuItem.children)];
    }
  }
  return matchingItems;
};

type MenuItems = Array<{
  active: Boolean,
  component: Object,
  featureId?: String,
  header: String,
  icon: String,
  name: String,
  path: String,
  children: MenuItems,
}>;

const filterUnauthorizedMenu = (routes: MenuItems, user: Object): MenuItems => {
  var filteredItems = [];
  routes.forEach((item) => {
    // if the menu have a featureId, we go futher to process the privileges.
    if (item.featureId) {
      if (item.children) {
        var filteredChildren = filterUnauthorizedMenu(item.children, user);
        if (filteredChildren.length) {
          item.children = filteredChildren;
          filteredItems.push(item);
        }
      } else {
        user.pagePrivileges.forEach((priv) => {
          if (item.featureId === priv) filteredItems.push(item);
        });
      }
    } else {
      // else if the menu dont have a featureId, means it is a public menu,
      // directly pass the fileter.
      filteredItems.push(item);
    }
  });
  return filteredItems;
};

/**
 * Initilizes the menu
 */
let savedData = {};

function* initMenuAndItems() {
  try {
    const user = getLoggedInUser();
    const menuItems = assignIds(filterUnauthorizedMenu(routes, user));
    if (menuItems.length) {
      savedData.menuItems = menuItems;
      yield put(initMenuSuccess(menuItems));
    }
  } catch (error) {}
}

/**
 * change the active menu item based on location
 */
function* changeActiveMenuFromLocation() {
  try {
    const activatedMenuItemIds = yield call(getActivatedMenuItemIds, savedData.menuItems);
    yield put(changeActiveMenuFromLocationSuccess(activatedMenuItemIds));
  } catch (error) {}
}

/**
 * Watchers
 */
export function* watchMenuInit(): any {
  yield takeEvery(INIT_MENU, initMenuAndItems);
}

export function* watchMenuChange(): any {
  yield takeEvery(CHANGE_ACTIVE_MENU_FROM_LOCATION, changeActiveMenuFromLocation);
}

function* appMenuSaga(): any {
  yield all([fork(watchMenuInit), fork(watchMenuChange)]);
}

export default appMenuSaga;
