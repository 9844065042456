import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { loginUserSuccess, loginUserFailed } from './actions';
import { LOGIN_USER, LOGOUT_USER } from './constants';

import { loginUser } from '@helpers/api/users';

const setSession = (sessionData: string) => {
  const cookies = new Cookies();
  if (sessionData) cookies.set('user', sessionData, { path: '/' });
  else cookies.remove('user', { path: '/' });
};

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password } }) {
  try {
    const resp = yield call(loginUser, username, password);

    if (resp.status === 'ok') {
      setSession(resp.data);
      yield put(loginUserSuccess(jwtDecode(resp.data)));
    } else {
      let message = '不明なエラーが発生しました';
      switch (resp.code) {
        case 'E04':
          message = 'ログイン試行回数が上限を超えました';
          break;
        case 'E01':
          message = 'ログイン情報に誤りがあります';
          break;
      }
      setSession(null);
      yield put(loginUserFailed(message));
    }
  } catch (error) {
    let message = '不明なエラーが発生しました';

    if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
      message = '通信エラーが発生しました';
    } else if (error.name === 'Error' && error.message === '500 Response') {
      message = '内部サーバエラーが発生しました';
    }
    yield put(loginUserFailed(message));
    setSession(null);
  }
}

function* logout({ payload: { history } }) {
  try {
    setSession(null);
    yield call(() => {
      history.push('/login');
    });
    // eslint-disable-next-line no-empty
  } catch (error) {}
}

export function* watchLoginUser(): any {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser(): any {
  yield takeEvery(LOGOUT_USER, logout);
}

function* authSaga(): any {
  yield all([fork(watchLoginUser), fork(watchLogoutUser)]);
}

export default authSaga;
