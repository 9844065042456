import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';

const isUserAuthenticated = (): boolean => {
  const user = getLoggedInUser();
  if (user && user.sessToken) return true;
  return false;
};

interface LoggedInUser {
  acctRole: number;
  firstName: string;
  id: string;
  lastName: string;
  pagePrivileges: string[];
  propPrivileges: string[];
  roleName: string;
  sessToken: string;
}

const getLoggedInUser = (): LoggedInUser | null => {
  const cookies = new Cookies();
  const user = cookies.get('user');

  if (typeof user == 'string') {
    try {
      const decode: LoggedInUser = jwtDecode(user);
      let roleName = '';
      switch (decode.acctRole) {
        case 0:
          roleName = 'アドミン';
          break;
        case 1:
          roleName = '契約管理者';
          break;
        case 2:
          roleName = '施設管理者';
          break;
        case 3:
          roleName = 'スタッフ';
          break;
      }
      return { ...decode, roleName };
    } catch {
      // if decode user cookie failed, return null
      return null;
    }
  }
  // if user cookie not exist, return null
  return null;
};

export { isUserAuthenticated, getLoggedInUser };
