import { all } from 'redux-saga/effects';

import appMenuSaga from './appMenu/saga';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function* rootSaga(getState: any): any {
  yield all([authSaga(), layoutSaga(), appMenuSaga()]);
}
