import { combineReducers } from 'redux';

import AppMenu from './appMenu/reducers';
import Auth from './auth/reducers';
import Layout from './layout/reducers';

export default combineReducers({
  Auth,
  AppMenu,
  Layout,
});
