import * as humps from 'humps';

import { appConfig } from '@constants/index';
import { getLoggedInUser } from '@helpers/authUtils';

export interface ApiResponse<T> {
  status: 'ok' | 'error';
  code?: string;
  data?: T;
}

async function fetchJSON(url: string, options: RequestInit): Promise<ApiResponse<never>> {
  const resp = await fetch(url, options);
  //if response code is not 2xx, raise a error
  if (!resp.ok) throw new Error(`${resp.status} Response`);
  const respJson = await resp.json();
  if (respJson.code === 'E03') window.location.href = '/logout';
  return respJson;
}

async function fetchBlob(url: string, options: RequestInit): Promise<Blob | undefined> {
  const resp = await fetch(url, options);
  if (resp.status === 200) {
    const respBlob = await resp.blob();
    return respBlob;
  }
}

/**
 * undefinedのパラメタを除外し、クエリ文字列を生成する関数
 * @param params Objectに保存しているクエリパラメタ
 * @returns paramsから値がundefinedのキーを削除したあと、クエリ文字列に変換したもの
 */
function parseParams(params: Record<string, string | undefined>): string {
  Object.keys(params).forEach((k) => {
    if (params[k] === undefined) delete params[k];
  });

  const urlParams = new URLSearchParams(params as Record<string, string>);
  return urlParams.toString();
}

function makeApiRequest(
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  path: string,
  body?: Record<string, unknown>,
  params?: Record<string, string | undefined>
): Promise<ApiResponse<never>> {
  const options: RequestInit = { method, mode: 'cors' };
  const sess = getLoggedInUser()?.sessToken ?? 'null';
  const url = new URL(appConfig.api.endpoint);

  url.pathname = path;
  const paramsParsed = humps.decamelizeKeys({ ...params, sess }) as Record<string, string | undefined>;
  url.search = parseParams(paramsParsed);

  if (method === 'POST' || method === 'PUT') {
    options.headers = { 'Content-Type': 'application/json' };
    if (body) options.body = JSON.stringify(humps.decamelizeKeys(body));
  }

  return fetchJSON(url.toString(), options);
}

function makeBlobRequest(path: string, params?: Record<string, string>): Promise<Blob | undefined> {
  const options: RequestInit = { method: 'GET', mode: 'cors' };
  const sess = getLoggedInUser()?.sessToken ?? 'null';
  const url = new URL(appConfig.api.endpoint);

  url.pathname = path;
  url.search = parseParams({ ...params, sess });

  return fetchBlob(url.toString(), options);
}

export { fetchJSON, fetchBlob, makeApiRequest, makeBlobRequest };
