export * from './layout';

export const appConfig = {
  env: {
    name: process.env.REACT_APP_ENV_NAME ?? 'development',
  },
  api: {
    endpoint: process.env.REACT_APP_API_ENDPOINT ?? 'http://127.0.0.1:5000/',
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    simpleRate: process.env.REACT_APP_ENV_NAME,
  },
};
