import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import { appConfig } from './constants';
import { configureStore } from './redux/store';
import * as serviceWorker from './serviceWorker';

// Initialize Sentry in staging and production environment
if (appConfig.env.name !== 'development') {
  Sentry.init({
    dsn: appConfig.sentry.dsn,
    // https://stackoverflow.com/questions/49384120
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    environment: appConfig.env.name,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: parseFloat(appConfig.sentry.simpleRate ?? '0'),
  });
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
