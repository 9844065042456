import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));

// dashboard
const Starter = React.lazy(() => import('../pages/Starter'));
const Dashboard = React.lazy(() => import('../pages/rms/Dashboard'));

// analytic
const DataResearch = React.lazy(() => import('../pages/analysis/Research'));

// pms
const Reservation = React.lazy(() => import('../pages/pms/Reservation'));
const ReservationDetail = React.lazy(() => import('../pages/pms/ReservationDetail'));
const ReservationEditor = React.lazy(() => import('../pages/pms/ReservationEditor'));
const RoomAllocation = React.lazy(() => import('../pages/pms/RoomAllocation'));
const RoomTypes = React.lazy(() => import('../pages/pms/RoomTypes'));
const Rooms = React.lazy(() => import('../pages/pms/Rooms'));
const ProjectReserveManage = React.lazy(() => import('../pages/pms/ProjectReserveManage'));
const ProjectReserveAdd = React.lazy(() => import('../pages/pms/ProjectReserveAdd'));
const SalesItem = React.lazy(() => import('../pages/pms/SalesItem'));

// rms
const SalesSummary = React.lazy(() => import('../pages/rms/SalesSummary'));
const ResidentsSales = React.lazy(() => import('../pages/rms/ResidentsSales'));
const DepositBalance = React.lazy(() => import('../pages/rms/DepositBalance'));
const AccountsReceivable = React.lazy(() => import('../pages/rms/AccountsReceivable'));
const PaymentDetails = React.lazy(() => import('../pages/rms/PaymentDetails'));
const CancelDetails = React.lazy(() => import('../pages/rms/CancelDetails'));
const OpeStatus = React.lazy(() => import('../pages/rms/OpeStatus'));
const PreviousDay = React.lazy(() => import('../pages/rms/PreviousDay'));
const BookingCurve = React.lazy(() => import('../pages/rms/BookingCurve'));
const SalesByKind = React.lazy(() => import('../pages/rms/SalesByKind'));
const GuestAnalysis = React.lazy(() => import('../pages/rms/GuestAnalysis'));
const GuestSummaryByPlan = React.lazy(() => import('../pages/rms/GuestSummaryByPlan'));

// settings
const Account = React.lazy(() => import('../pages/settings/Account'));
const Properties = React.lazy(() => import('../pages/settings/Properties'));
const Staffs = React.lazy(() => import('../pages/settings/Staffs'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!isUserAuthenticated()) {
        // if user not logged-in redirect to logout.
        // notice we redirect to logout instead of login since we need
        // to refresh state.Auth.user to null in Redux.
        // As if user cleared their cookies, the state.Auth.user still maintain
        // the user data and may cause some bug, redirect to logout will reset
        // the state.Auth.users
        return <Redirect to={{ pathname: '/logout', state: { from: props.location } }} />;
      }

      const loggedInUser = getLoggedInUser();
      // check if route is restricted by role
      if (roles && roles.indexOf(loggedInUser.acctRole) === -1) {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: '/' }} />;
      }

      // authorised so return component
      return <Component {...props} />;
    }}
  />
);

// root routes
const rootRoute = {
  path: '/',
  exact: true,
  // eslint-disable-next-line react/display-name
  component: () => <Redirect to="/dashboard" />,
  route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
  path: '/dashboard',
  name: 'ダッシュボード',
  featureId: 'A2-A1',
  icon: 'uil-home-alt',
  header: 'ホーム',
  component: Dashboard,
  route: PrivateRoute,
};

// analysis
const analysisRoutes = {
  path: '/analysis',
  name: '競合施設分析',
  featureId: 'A3-A0',
  route: PrivateRoute,
  icon: 'uil-chart-bar',
  header: 'アプリ',
  children: [
    {
      path: '/analysis/research',
      name: 'データリサーチ',
      featureId: 'A3-A1',
      component: DataResearch,
      icon: 'mdi mdi-numeric-1-box-outline',
      route: PrivateRoute,
    },
  ],
};

const pmsRoutes = {
  name: '施設管理',
  featureId: 'A4-A0',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'uil-calendar-alt',
  children: [
    {
      path: '/pms/allocation',
      name: '部屋割り',
      featureId: 'A4-A1',
      component: RoomAllocation,
      icon: 'mdi mdi-numeric-1-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/pms/rsv/list',
      name: '予約一覧',
      featureId: 'A4-A2',
      component: Reservation,
      icon: 'mdi mdi-numeric-2-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/pms/room/status',
      name: '部屋一覧',
      featureId: 'A4-A3',
      component: Starter,
      icon: 'mdi mdi-numeric-3-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/pms/guest',
      name: 'ゲスト一覧',
      featureId: 'A4-A4',
      component: Starter,
      icon: 'mdi mdi-numeric-4-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/pms/projectReserveManage',
      name: '案件予約管理',
      featureId: 'A4-A4',
      component: ProjectReserveManage,
      icon: 'mdi mdi-numeric-5-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/pms/ProjectReserveAdd',
      name: '案件予約登録',
      featureId: 'A4-A4',
      component: ProjectReserveAdd,
      icon: 'mdi mdi-numeric-6-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/pms/rsv/new',
      name: '新規予約',
      featureId: 'A0-A1',
      component: ReservationEditor,
      route: PrivateRoute,
    },
    {
      path: '/pms/rsv/edit',
      name: '予約変更',
      featureId: 'A0-A2',
      component: ReservationEditor,
      route: PrivateRoute,
    },
    {
      path: '/pms/rsv/detail',
      name: '予約詳細',
      featureId: 'A0-A3',
      component: ReservationDetail,
      route: PrivateRoute,
    },
  ],
};

const rmsRoutes = {
  name: '売上管理',
  featureId: 'A6-A0',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'uil-dollar-alt',
  children: [
    {
      path: '/rms/sales-summary',
      name: '売上サマリ',
      featureId: 'A6-A1',
      component: SalesSummary,
      icon: 'mdi mdi-numeric-1-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/rms/residents-sales',
      name: '売上入金明細',
      featureId: 'A6-A1',
      component: ResidentsSales,
      icon: 'mdi mdi-numeric-2-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/rms/deposit-balance',
      name: '預り金明細',
      featureId: 'A6-A1',
      component: DepositBalance,
      icon: 'mdi mdi-numeric-3-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/rms/accounts-receivable',
      name: '未収金明細',
      featureId: 'A6-A1',
      component: AccountsReceivable,
      icon: 'mdi mdi-numeric-4-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/rms/payment-details',
      name: '入金明細',
      featureId: 'A6-A1',
      component: PaymentDetails,
      icon: 'mdi mdi-numeric-6-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/rms/cancel-details',
      name: 'キャンセル・ノーショー明細',
      featureId: 'A6-A1',
      component: CancelDetails,
      icon: 'mdi mdi-numeric-7-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/rms/ope-status',
      name: '稼働状況表',
      featureId: 'A6-A1',
      component: OpeStatus,
      icon: 'mdi mdi-numeric-8-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/rms/previous-day',
      name: '前日修正表',
      featureId: 'A6-A1',
      component: PreviousDay,
      icon: 'mdi mdi-numeric-9-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/rms/booking-curve',
      name: '11.ブッキングカーブ',
      featureId: 'A6-A1',
      component: BookingCurve,
      icon: 'mdi mdi-numeric-11-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/rms/sales-by-kind',
      name: '12.分類別売上',
      featureId: 'A6-A1',
      component: SalesByKind,
      icon: 'mdi mdi-numeric-12-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/rms/guest-analysis',
      name: '13.ゲスト別分析表',
      featureId: 'A6-A1',
      component: GuestAnalysis,
      icon: 'mdi mdi-numeric-13-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/rms/guest-summary-by-plan',
      name: '14.プラン別顧客属性集計表',
      featureId: 'A6-A1',
      component: GuestSummaryByPlan,
      icon: 'mdi mdi-numeric-14-box-outline',
      route: PrivateRoute,
    },
  ],
};

const pmsSettings = {
  name: '施設設定',
  featureId: 'A4-B0',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'uil-wrench',
  children: [
    {
      path: '/pms/plan',
      name: 'プラン設定',
      featureId: 'A5-A1',
      component: Starter,
      icon: 'mdi mdi-numeric-1-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/pms/room/type',
      name: '部屋タイプ設定',
      featureId: 'A5-A2',
      component: RoomTypes,
      icon: 'mdi mdi-numeric-2-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/pms/room/list',
      name: '部屋設定',
      featureId: 'A5-A3',
      component: Rooms,
      icon: 'mdi mdi-numeric-3-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/pms/sales-item',
      name: '売上科目設定',
      featureId: 'A5-A4',
      component: SalesItem,
      icon: 'mdi mdi-numeric-4-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/pms/deposit-item',
      name: '入金科目設定',
      featureId: 'A5-A5',
      component: Starter,
      icon: 'mdi mdi-numeric-5-box-outline',
      route: PrivateRoute,
    },
  ],
};

const settingsRoutes = {
  path: '/settings',
  name: '全体設定',
  featureId: 'A1-A0',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'uil-cog',
  children: [
    {
      path: '/settings/account',
      name: 'アカウント設定',
      featureId: 'A1-A1',
      component: Account,
      icon: 'mdi mdi-numeric-1-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/settings/staff',
      name: 'スタッフ管理',
      featureId: 'A1-A2',
      component: Staffs,
      icon: 'mdi mdi-numeric-2-box-outline',
      route: PrivateRoute,
    },
    {
      path: '/settings/property',
      name: '自社施設管理',
      featureId: 'A1-A3',
      // component: Starter,
      component: Properties,
      icon: 'mdi mdi-numeric-3-box-outline',
      route: PrivateRoute,
    },
  ],
};

// auth
const authRoutes = {
  path: '',
  name: 'Auth',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      route: Route,
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout,
      route: Route,
    },
  ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

const authProtectedRoutes = [dashboardRoutes, analysisRoutes, pmsRoutes, rmsRoutes, pmsSettings, settingsRoutes];

// All routes
const allRoutes = [rootRoute, authRoutes, ...authProtectedRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
