import { makeApiRequest, ApiResponse } from './fetch';

interface GetUserResp {
  [key: string]: unknown;
  acctRole: number;
  createTime: string;
  department?: string;
  email?: string;
  firstKana?: string;
  firstName: string;
  id: string;
  lastKana?: string;
  lastName: string;
  pagePrivileges: string[];
  propPrivileges: string[];
  telNumber?: string;
}

type GetSubUsersResp = Array<{
  createTime: string;
  department?: string;
  email?: string;
  firstName: string;
  id: string;
  isDisabled: boolean;
  lastName: string;
  propPrivileges: string[];
  telNumber?: string;
}>;

interface AddUserReq {
  [key: string]: unknown;
  acctRole: number;
  department?: string;
  email?: string;
  firstKana?: string;
  firstName: string;
  lastKana?: string;
  lastName: string;
  newPassword: string;
  pagePrivileges: string[];
  propPrivileges: string[];
  telNumber?: string;
}

interface ModUserSelfReq {
  [key: string]: unknown;
  department?: string;
  email?: string;
  firstKana?: string;
  firstName?: string;
  id: string;
  lastKana?: string;
  lastName?: string;
  newPassword?: string;
  password: string;
  telNumber?: string;
}

interface ModUserSubReq {
  [key: string]: unknown;
  acctRole?: number;
  department?: string;
  email?: string;
  firstKana?: string;
  firstName?: string;
  id: string;
  isDeleted?: boolean;
  isDisabled?: boolean;
  lastKana?: string;
  lastName?: string;
  pagePrivileges?: string[];
  propPrivileges?: string[];
  telNumber?: string;
}

/**
 * ユーザログイン
 * @param id ユーザID
 * @param password ユーザパスワード
 * @returns ユーザ基本情報、セッショントークンなどを含めたJWTデータ
 */
function loginUser(id: string, password: string): Promise<ApiResponse<string>> {
  const path = '/user/session';
  const body = { id, password };
  return makeApiRequest('POST', path, body);
}

/**
 * セッショントークン検証
 * @returns セッションが有効なものであれば、ログイン中のユーザIDが返される
 */
function loginCheck(): Promise<ApiResponse<{ id: string }>> {
  const path = '/user/session';
  return makeApiRequest('GET', path);
}

/**
 * ユーザログアウト
 * @returns `{ msg: 'deleted' }` というメッセージを返される（成功した場合）
 */
function logoutUser(): Promise<ApiResponse<{ msg: string }>> {
  const path = '/user/session';
  return makeApiRequest('DELETE', path);
}

/**
 * 指定するユーザ情報を取得する
 * @param id 対象ユーザのID、指定しない場合はログイン中のユーザとなる。
 * @returns ユーザの詳細データ
 */
function getUser(id?: string): Promise<ApiResponse<GetUserResp>> {
  const path = '/user';
  const params = { id };
  return makeApiRequest('GET', path, undefined, params);
}

/**
 * ログイン中のユーザが管理できるサブユーザの一覧を取得する
 * @param propId 指定IDの施設に所属していることを条件に、取得するサブユーザをフィルターする
 * @returns サブユーザの基本情報リスト
 */
function getSubUsers(propId?: string): Promise<ApiResponse<GetSubUsersResp>> {
  const path = '/user';
  const params = { do: 'listChild', propId };
  return makeApiRequest('GET', path, undefined, params);
}

/**
 * ユーザを追加する
 * @param user 追加するユーザのデータ
 * @returns 追加されたユーザのID（成功した場合）
 */
function addUser(userNew: AddUserReq): Promise<ApiResponse<{ id: string }>> {
  const path = '/user';
  return makeApiRequest('POST', path, userNew);
}

/**
 * ログインしたユーザ自身のデータを更新する
 * @param user 更新データ
 * @returns 更新したユーザのID（成功した場合）
 */
function modUserSelf(userModified: ModUserSelfReq): Promise<ApiResponse<{ id: string }>> {
  const path = '/user';
  return makeApiRequest('PUT', path, userModified);
}

/**
 * サブユーザ（同じ施設に所属し、acctRoleがより低いユーザ）のデータを更新する
 * @param user 更新データ
 * @returns 更新したユーザのID（成功した場合）
 */
function modUserSub(userModified: ModUserSubReq): Promise<ApiResponse<{ id: string }>> {
  const path = '/user';
  return makeApiRequest('PUT', path, userModified);
}

/**
 * ユーザアカウントの有効/無効を切り替える
 * @param id 対象ユーザのID
 * @param isDisabled falseのとき有効に、trueのとき無効に切り替える
 * @returns 有効/無効したユーザのID（成功した場合）
 */
function disableUser(id: string, isDisabled: boolean): Promise<ApiResponse<{ id: string }>> {
  const path = '/user';
  const body: ModUserSubReq = { id, isDisabled };
  return makeApiRequest('PUT', path, body);
}

/**
 * ユーザアカウントを削除する
 * @param id 対象ユーザのID
 * @returns 削除したユーザのID（成功した場合）
 */
function deleteUser(id: string): Promise<ApiResponse<{ id: string }>> {
  const path = '/user';
  const body: ModUserSubReq = { id, isDeleted: true };
  return makeApiRequest('PUT', path, body);
}

export {
  addUser,
  deleteUser,
  disableUser,
  getSubUsers,
  getUser,
  loginCheck,
  loginUser,
  logoutUser,
  modUserSelf,
  modUserSub,
};
