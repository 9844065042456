import React, { Component } from 'react';

import Routes from './routes/Routes';

// Themes
import './assets/scss/Saas.scss';

class App extends Component {
  render(): React.ReactElement {
    return <Routes></Routes>;
  }
}

export default App;
